import {Component, OnInit, OnDestroy} from '@angular/core';
import {DragulaService} from 'ng2-dragula';
import {PhotoMergeService} from '../../services/photo-merge.service';
import {Router} from '@angular/router';
import {PhotoMergeParam} from '../../models/photo-merge-param';
import {AlbumCoverService} from '../../services/album-cover.service';
import {ApiService} from '../../services/api.service';

declare var isDraggingAndPreventScroll: boolean;

@Component({
  selector: 'app-merge',
  templateUrl: './merge.component.html',
  styleUrls: ['./merge.component.css']
})
export class MergeComponent implements OnInit, OnDestroy {
  DragulaInstance = 'PhotoPreview';

  photoMergeParam: PhotoMergeParam;

  // 九宫格拼图时的参数
  jggPositionTop: number;
  jggPhotoWidth: number;

  windowWidth: number;
  windowHeight: number;
  isMerging = false;
  mergeComplete = false;
  mergedImageUrl: string;
  getGifInterval: any;

  constructor(
    private apiService: ApiService,
    private photoMergeService: PhotoMergeService,
    private router: Router,
    private dragulaService: DragulaService,
    private albumCoverService: AlbumCoverService,
  ) {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  ngOnInit() {
    this.photoMergeParam = this.photoMergeService.restoreMergeParam();
    if (!this.photoMergeParam || this.photoMergeParam.photos.length === 0) {
      this.router.navigate(['album/not-found']);
    }
    // 生成gif
    if (this.photoMergeParam.mergeTemplate === 'gif') {
      this.isMerging = true;
      this.getGifInterval = setInterval(() => {
        this.onShowGif()
      }, 1000)
      return
    }
    if (this.photoMergeParam.mergeTemplate === 'jgg') {
      this.jggPositionTop = (window.innerHeight - window.innerWidth) / 2;
      this.jggPhotoWidth = (window.innerWidth - 8) / 3;
    }
    this.mergeComplete = false;
    this.mergedImageUrl = undefined;

    // 为了保证返回到相册页面时不再显示封面
    this.albumCoverService.setShowCover(false, this.photoMergeParam.albumUuid);

    this.dragulaService.drag(this.DragulaInstance).subscribe(() => {
      isDraggingAndPreventScroll = true;
    });
    this.dragulaService.dragend(this.DragulaInstance).subscribe(() => {
      isDraggingAndPreventScroll = false;
    });
    this.dragulaService.drop(this.DragulaInstance).subscribe(() => {
      isDraggingAndPreventScroll = false;
    });

    // 预览图片时阻止滚动
    document.addEventListener('touchmove', this.preventDefault, {passive: false});
    document.addEventListener('touchforcechange', this.preventDefault, {passive: false});
  }

  onShowGif () {
    const gifUuid = this.photoMergeParam.gifUuid
    this.apiService.getGif(gifUuid).subscribe(data => {
      if (data) {
        this.mergeComplete = true
        this.mergedImageUrl = data.gifPhoto.photoUrl
        this.isMerging = false;
        clearInterval(this.getGifInterval)
      }
    });
  }

  onMergeBtn() {
    this.isMerging = true;
    this.photoMergeService.merge(this.photoMergeParam, dataUrl => {
      this.mergeComplete = true;
      this.mergedImageUrl = dataUrl;
      this.isMerging = false;
      document.documentElement.scrollTo(0, 0);
    });
  }

  ngOnDestroy() {
    document.removeEventListener('touchmove', this.preventDefault);
    document.removeEventListener('touchforcechange', this.preventDefault);
    this.dragulaService.destroy(this.DragulaInstance);
  }

  preventDefault(e) {
    if (isDraggingAndPreventScroll) {
      e.preventDefault();
    }
  }
}
