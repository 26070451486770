import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {AlbumStatusEnum} from '../../constants/album-status-enum';
import {Album} from '../../models/album';
import {ApiService} from '../../services/api.service';
import {ValidateAccessParam} from '../../models/validate-access-param';
import {ApiResponse} from '../../models/api-response';

@Component({
  selector: 'app-album-error-popup',
  templateUrl: './album-error-popup.component.html',
  styleUrls: ['./album-error-popup.component.css']
})
export class AlbumErrorPopupComponent implements OnInit {
  AlbumStatusEnum = AlbumStatusEnum;

  @Input()
  albumStatus: AlbumStatusEnum;
  @Input()
  errorMsg: string;
  @Input()
  album: Album;
  @Output()
  validateSuccess = new EventEmitter();

  public validateParams: ValidateAccessParam = new ValidateAccessParam();
  public validateFailed = false;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    if (this.album) {
      this.validateParams.albumUuid = this.album.uuid;
    }
  }

  onValidate() {
    this.apiService.validateAccess(this.validateParams).subscribe((apiResponse: ApiResponse<any>) => {
      if (apiResponse.code !== 0) {
        this.validateFailed = true;
      } else {
        this.validateSuccess.emit();
      }
    });
  }
}
