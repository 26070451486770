import {Photo} from './photo';

export class PhotoList {
  // 时间轴照片
  show: Boolean;
  hour: String;
  photos: Photo[];
  // 普通照片
  list: Photo[];
  start: number;
  limit: number;
  total: number;
  hasNext: boolean;
}
