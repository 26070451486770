import {Component, Input, OnInit} from '@angular/core';
import {Album} from '../../models/album';
import {PhotoSizeService} from '../../services/photo-size.service';

declare var Swipe: any;

@Component({
  selector: 'app-album-head-image',
  templateUrl: './album-head-image.component.html',
  styleUrls: ['./album-head-image.component.css']
})
export class AlbumHeadImageComponent implements OnInit {

  @Input()
  album: Album;

  headImages: string[] = [];
  headImageLink: string = null;
  activeHeadImageIndex = 0;

  constructor(private photoSizeService: PhotoSizeService) { }

  ngOnInit() {
    const headImageResizeParam = this.photoSizeService.headImageResizeParam();
    if (this.album.headImage1Url) {
      this.headImages.push(this.album.headImage1Url + headImageResizeParam);
    }
    if (this.album.headImage2Url) {
      this.headImages.push(this.album.headImage2Url + headImageResizeParam);
    }
    if (this.album.headImage3Url) {
      this.headImages.push(this.album.headImage3Url + headImageResizeParam);
    }
    if (this.album.headImageLink) {
      this.headImageLink = this.album.headImageLink;
    }

    if (this.headImages.length > 1) {
      setTimeout(() => {
        new Swipe(document.getElementById('head-image-swipe'), {
          startSlide: 0,
          speed: 400,
          auto: 3000,
          continuous: true,
          disableScroll: false,
          stopPropagation: false,
          callback: (index, elem) => {
            this.activeHeadImageIndex = index;
          },
          // transitionEnd: (index, elem) => {}
        });
      });
    }
  }

}
