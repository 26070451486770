import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageLoadService {

  constructor() { }

  // 加载完所有图片后回调
  loadAllImages(imageUrls: string[], callback: (imageMap: Map<string, HTMLImageElement>) => void) {
    const imageMap: Map<string, HTMLImageElement> = new Map<string, HTMLImageElement>();
    let loadedCount = 0;
    for (const url of imageUrls) {
      const image = new Image();
      image.setAttribute('crossOrigin', 'anonymous');
      image.onload = () => {
        ++loadedCount;
        imageMap.set(url, image);
        if (loadedCount === imageUrls.length) {
          callback(imageMap);
        }
      };
      image .src = url;
    }
  }
}
