import {Injectable} from '@angular/core';
import {Photo} from '../models/photo';
import {PhotoSizeService} from './photo-size.service';
import {ImageLoadService} from './image-load.service';
import {PhotoMergeParam} from '../models/photo-merge-param';

@Injectable({
  providedIn: 'root'
})
export class PhotoMergeService {

  Templates = {
    long: {
      name: 'long',
      minPhotoNum: 2,
      maxPhotoNum: 10
    },
    jgg: {
      name: 'jgg',
      minPhotoNum: 9,
      maxPhotoNum: 9
    },
    gif: {
      name: 'gif',
      minPhotoNum: 2,
      maxPhotoNum: 10
    }
  };

  private canvasElement;

  constructor(private photoSizeService: PhotoSizeService, private imageLoadService: ImageLoadService) {
    this.canvasElement = document.createElement('canvas');
  }

  saveMergeParam(photoMergeParam: PhotoMergeParam) {
    localStorage.setItem('photoMergeParam', JSON.stringify(photoMergeParam));
  }

  restoreMergeParam(): PhotoMergeParam {
    const value = localStorage.getItem('photoMergeParam');
    return JSON.parse(value)
  }

  merge(photoMergeParam: PhotoMergeParam, afterMerge: (dataUrl: string) => void) {
    if (photoMergeParam.mergeTemplate === 'long') {
      this.mergeLong(photoMergeParam, afterMerge);
    } else if (photoMergeParam.mergeTemplate === 'jgg') {
      this.mergeJgg(photoMergeParam, afterMerge);
    }
  }

  drawText (ctx, str, canvasWidth, canvasHeight, initHeight, h) {
    ctx.font = "48px bold Microsoft";
    this.canvasElement.height = canvasHeight + Math.ceil(ctx.measureText(str).width / (canvasWidth - 200)) * 70 + h;
    ctx.font = "48px bold Microsoft"; //重新定义画布的高度后，需要重新定义字体的大小，否则变成默认值
    ctx.textAlign = 'center';
    var lastSunStrIndex = 0; //每次开始截取的字符串的索引
    var contentWidth = 0;
    if (ctx.measureText(str).width <= canvasWidth) {
      ctx.fillText(str, canvasWidth / 2, initHeight);
    } else {
      for (let i = 0; i < str.length; i++) {
        contentWidth += ctx.measureText(str[i]).width;
        if (contentWidth > canvasWidth - 200) {
            ctx.fillText(str.substring(lastSunStrIndex, i), canvasWidth / 2, initHeight) //绘制未截取的部分
            initHeight += 70;
            contentWidth = 0;
            lastSunStrIndex = i;
        }
        if (i == str.length - 1) {
            ctx.fillText(str.substring(lastSunStrIndex, i + 1), canvasWidth / 2, initHeight);
        }
      }
    }
    return initHeight
  }

  // 长图
  private mergeLong(photoMergeParam: PhotoMergeParam, afterMerge: (dataUrl: string) => void) {
    const borderSize = 6;
    const photoWidth = this.photoSizeService.maxPhotoWidth;
    const logoPadding = 15;
    const logoHeight = 44;
    const logoWidth = 60;
    const logoUrl = '//file-dev.gogobamboo.com/h5_code/assets/img/logo-black-transparent.png';

    // 计算总高
    let totalHeight = borderSize;
    for (const photo of photoMergeParam.photos) {
      totalHeight += photo.loadedHeight + borderSize;
    }
    totalHeight += logoHeight + logoPadding * 2 + borderSize;

    // 画布尺寸
    const canvasWidth = borderSize * 2 + photoWidth;
    const canvasHeight = totalHeight;
    this.canvasElement.width = canvasWidth;
    this.canvasElement.height = canvasHeight;
    const ctx = this.canvasElement.getContext('2d');
    // 清空画布
    ctx.fillStyle = '#333';

    let str = photoMergeParam.name;
    var h = 40; // 图片局距离文字的高度
    var initHeight = 70; // 一行文字的高度
    if (str === '') {
      h = 0
      initHeight = 0
    } else {
      initHeight = this.drawText(ctx, str, canvasWidth, canvasHeight, initHeight, h)
    }
    ctx.fill();

    // 开始加载图片
    const imageUrls: string[] = [];
    for (const photo of photoMergeParam.photos) {
      imageUrls.push(photo.displayUrl);
    }
    imageUrls.push(logoUrl);
    this.imageLoadService.loadAllImages(imageUrls, imageMap => {
      // 开始画图
      let curHeight = borderSize + initHeight;
      for (const photo of photoMergeParam.photos) {
        const image = imageMap.get(photo.displayUrl);
        ctx.drawImage(image, borderSize, curHeight + h);
        curHeight += photo.loadedHeight + borderSize;
      }
      // 画logo
      const dx = canvasWidth / 2 - logoWidth / 2;
      ctx.drawImage(imageMap.get(logoUrl), dx, curHeight + logoPadding + h, logoWidth, logoHeight);
      afterMerge(this.canvasElement.toDataURL('image/png'));
      // const newWindow = window.open('about:blank', 'image from canvas');
      // newWindow.document.write('<img src="' + dataUrl + '" alt="from canvas"/>');
    });
  }

  // 九宫格
  private mergeJgg(photoMergeParam: PhotoMergeParam, afterMerge: (dataUrl: string) => void) {
    const borderSize = 6;
    const logoPadding = 15;
    const logoHeight = 44;
    const logoWidth = 60;
    const logoUrl = '//file-dev.gogobamboo.com/h5_code/assets/img/logo-black-transparent.png';

    // 图片尺寸（正方形边长） => 所有图片最小的一边
    let photoSize = 9007199254740991;
    for (const photo of photoMergeParam.photos) {
      if (photo.loadedHeight < photoSize) {
        photoSize = photo.loadedHeight;
      }
      if (photo.loadedWidth < photoSize) {
        photoSize = photo.loadedWidth;
      }
    }

    // 画布尺寸
    const canvasWidth = borderSize * 4 + photoSize * 3;
    const canvasHeight = canvasWidth + logoPadding * 2 + logoHeight;
    this.canvasElement.width = canvasWidth;
    this.canvasElement.height = canvasHeight;
    const ctx = this.canvasElement.getContext('2d');
    // 清空画布
    ctx.fillStyle = '#333';

    let str = photoMergeParam.name;
    var h = 40; // 图片局距离文字的高度
    var initHeight = 70; // 一行文字的高度
    if (str === '') {
      h = 0
      initHeight = 0
    } else {
      initHeight = this.drawText(ctx, str, canvasWidth, canvasHeight, initHeight, h)
    }
    ctx.fill();

    // 开始加载图片
    const imageUrls: string[] = [];
    for (const photo of photoMergeParam.photos) {
      imageUrls.push(photo.displayUrl);
    }
    imageUrls.push(logoUrl);
    this.imageLoadService.loadAllImages(imageUrls, imageMap => {
      // 开始画图
      let curHeight = borderSize + initHeight;
      let curWidth = borderSize;
      for (let i = 0; i < photoMergeParam.photos.length; ++i) {
        const photo = photoMergeParam.photos[i];
        const image = imageMap.get(photo.displayUrl);
        // 实际截取的图片部分：先计算图片最小的边，如果最小的边都比photoSize大，就用图片最小的边，画的时候缩小
        const clipSquareSize = Math.max(photoSize, Math.min(photo.loadedWidth, photo.loadedHeight));
        let sx = 0;
        let sy = 0;
        if (photo.loadedWidth > clipSquareSize) {
          sx = (photo.loadedWidth - clipSquareSize) / 2;
        }
        if (photo.loadedHeight > clipSquareSize) {
          sy = (photo.loadedHeight - clipSquareSize) / 2;
        }
        ctx.drawImage(image, sx, sy, clipSquareSize, clipSquareSize, curWidth, curHeight + h, photoSize, photoSize);
        if (i % 3 === 2) {
          curWidth = borderSize;
          curHeight += photoSize + borderSize;
        } else {
          curWidth += photoSize + borderSize;
        }
      }
      // 画logo
      const dx = canvasWidth / 2 - logoWidth / 2;
      ctx.drawImage(imageMap.get(logoUrl), dx, curHeight + logoPadding + h, logoWidth, logoHeight);
      afterMerge(this.canvasElement.toDataURL('image/png'));
      // const newWindow = window.open('about:blank', 'image from canvas');
      // newWindow.document.write('<img src="' + dataUrl + '" alt="from canvas"/>');
    });
  }

}
