import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiResponse} from '../models/api-response';
import {map} from 'rxjs/operators';
import {AlbumResult} from '../models/album-result';
import {PhotoList} from '../models/photo-list';
import {PhotoTimelineList} from '../models/photo-timeline-list';
import {PhotoSearchParam} from '../models/photo-search-param';
import {ValidateAccessParam} from '../models/validate-access-param';
import {WxJsApiConfig} from '../models/wx-js-api-config';
import {AlbumTimeline} from '../models/album-timeline';
import {PhotoMergeParam} from '../models/photo-merge-param';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  serverUrl = environment.serverUrl;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
  };

  constructor(private http: HttpClient) { }

  getAlbum(albumUuid: string): Observable<ApiResponse<AlbumResult>> {
    return this.http.get<ApiResponse<AlbumResult>>(this.serverUrl + '/get-album?albumUuid=' + albumUuid, this.httpOptions);
  }

  getPhoto(params: PhotoSearchParam): Observable<PhotoList> {
    return this.http.post<ApiResponse<PhotoList>>(this.serverUrl + '/get-photo', params, this.httpOptions).pipe(
      map(res => this.getDataFromApiResponse(res, null))
    );
  }

  getTimelinePhoto(params: PhotoSearchParam): Observable<PhotoTimelineList> {
    return this.http.post<ApiResponse<PhotoTimelineList>>(this.serverUrl + '/get-photo-time-group', params, this.httpOptions).pipe(
      map(res => this.getDataFromApiResponse(res, null))
    );
  }

  validateAccess(params: ValidateAccessParam): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(this.serverUrl + '/validate-access', params, this.httpOptions);
  }

  getWxJsApiConfig(url: string): Observable<WxJsApiConfig> {
    const apiUrl = this.serverUrl + '/gen-token?url=' + encodeURIComponent(url);
    return this.http.get<ApiResponse<any>>(apiUrl, this.httpOptions).pipe(
      map(res => {
        const data = this.getDataFromApiResponse(res, null);
        if (null == data) {
          console.log('jsApiConfigError, res:', res);
          return {};
        }
        return data.config;
      })
    );
  }

  incShareTimes(albumUuid: string): Observable<any> {
    return this.http.post<ApiResponse<any>>(this.serverUrl + '/increase-album-share-count', {albumUuid}, this.httpOptions).pipe(
      map(res => this.getDataFromApiResponse(res, null))
    );
  }

  getAlbumTimeline(albumUuid: string): Observable<AlbumTimeline[]> {
    return this.http.get<ApiResponse<any>>(this.serverUrl + '/get-album-timeline?albumUuid=' + albumUuid, this.httpOptions).pipe(
      map(res => {
        const data = this.getDataFromApiResponse(res, null);
        return null == data ? null : data.list;
      })
    );
  }

  generateGif(albumUuid: string, photoMergeParam: PhotoMergeParam): Observable<any> {
    return this.http.post<ApiResponse<any>>(this.serverUrl + '/generate-gif', { albumUuid, photos: photoMergeParam.photos }, this.httpOptions).pipe(
      map(res => this.getDataFromApiResponse(res, null))
    );
  }

  getGif(gifUuid: string): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.serverUrl + '/get-gif-photo?gifUuid=' + gifUuid, this.httpOptions).pipe(
      map(res => this.getDataFromApiResponse(res, null))
    );
  }

  private getDataFromApiResponse<T>(res: ApiResponse<T>, defaultResult?: T): T {
    if (0 === res.code) {
      return res.data;
    }
    return defaultResult;
  }

}
