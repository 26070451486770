import {PhotoCategory} from "./photo-category";
import {ShareTemplate} from "./share-template";

export class Album {
  uuid: string;
  name: string;
  summaryType: number;
  summary: string;
  summaryImageUrl: string;
  customerName: string;
  accessType: number;
  accessQuestion: string;
  coverType: number;
  coverImageUrl: string;
  coverVideoUrl: string;
  coverVideoBgUrl: string;
  headImageType: number;
  headImage1Url: string;
  headImage2Url: string;
  headImage3Url: string;
  headImageLink: string;
  watermarkType: number;
  watermarkImageUrl: string;
  subscriptType: number;
  subscriptImageUrl: string;
  viewCount: number;
  shareCount: number;

  headImageRatio: number;
  photoRatio: number;
  photoColumn: number;
  showTimeline: number;
  activityImageUrl: string;
  photoQuality: number;
  bgMusicUrl: string;
  shareImageUrl: string;
  shareTitle: string;
  shareSummary: string;
  footerLink: string;
  footerText: string;
  // "全部" 这个分类是否放在所有分类最前面：1 是，0 否
  allCategoryAtFirst: number;

  imageCount: number;
  categoryList: PhotoCategory[];
  shareTemplateList: ShareTemplate[];
  floatBtnLink: string;
  floatBtnSwitch: number;

}
