import {Component, Input, OnChanges, OnInit, SimpleChanges, HostListener, ViewChild, ElementRef} from '@angular/core';
import {Album} from '../../models/album';
import {AlbumStatusEnum} from '../../constants/album-status-enum';
import {ImageLoadService} from '../../services/image-load.service';
import {PhotoSizeService} from '../../services/photo-size.service';
import {AlbumCoverService} from '../../services/album-cover.service';

@Component({
  selector: 'app-album-cover',
  templateUrl: './album-cover.component.html',
  styleUrls: ['./album-cover.component.css']
})
export class AlbumCoverComponent implements OnInit, OnChanges {

  @Input()
  public album: Album;
  @Input()
  public albumStatus: AlbumStatusEnum = AlbumStatusEnum.STATUS_LOADING;
  @ViewChild('coverVideo', {static: false})
  public coverVideo: ElementRef;

  public showLogo = true;
  public showCover = false;
  public coverImageUrl: string;
  public hasActivity = false;
  public showActivityImage = false;
  public showCoverVideo = false;
  public showVideoPlayButton = false;

  public coverWidth = window.innerWidth;
  public coverHeight = window.innerHeight;
  private lastScrollY: number;
  public coverPositionTop: number;

  private coverResizeParam = '?imageView2/1/w/' + window.innerWidth + '/h/' + window.innerHeight + '/interlace/1/q/100';
  private videoFrameParam;

  constructor(
    private imageLoadService: ImageLoadService,
    private photoSizeService: PhotoSizeService,
    private albumCoverService: AlbumCoverService,
  ) { }

  ngOnInit() {
    this.videoFrameParam = '?vframe/png/offset/0/w/' + this.photoSizeService.maxPhotoWidth;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.albumStatus === undefined) {
      return;
    }
    if (changes.albumStatus.currentValue === AlbumStatusEnum.STATUS_LOADING) {
      this.showLogo = true;
      this.showCover = false;
      document.body.style.overflow = 'auto'
      return;
    }
    if (changes.albumStatus.currentValue === AlbumStatusEnum.STATUS_LOADED &&
      this.album.coverType !== 0 && this.albumCoverService.getShowCover(this.album.uuid)
    ) {
      this.createCover();
    } else {
      this.showLogo = false;
      this.showCover = false;
      document.body.style.overflow = 'auto'
    }
  }

  createCover() {
    const images = [];
    // 图片封面
    if (this.album.coverType === 1 && this.album.coverImageUrl) {
      this.coverImageUrl = this.album.coverImageUrl;
      // this.coverImageUrl = this.album.coverImageUrl + this.coverResizeParam;
      images.push(this.coverImageUrl);
      if (this.album.activityImageUrl) {
        this.hasActivity = true;
        images.push(this.album.activityImageUrl);
      }
      this.imageLoadService.loadAllImages(images, () => {
        this.showCover = true;
        document.body.style.overflow = 'hidden'
        this.showLogo = false;
      });

    // 视频封面
    } else if (this.album.coverType === 2 && this.album.coverVideoUrl) {
      if (this.album.coverVideoBgUrl) {
        this.coverImageUrl = this.album.coverVideoBgUrl;
        // this.coverImageUrl = this.album.coverVideoBgUrl + this.coverResizeParam;
        images.push(this.coverImageUrl);
      } else {
        this.coverImageUrl = this.album.coverVideoUrl + this.videoFrameParam;
        images.push(this.coverImageUrl);
      }
      this.showCoverVideo = true;
      this.showVideoPlayButton = true;
      if (images.length > 0) {
        this.imageLoadService.loadAllImages(images, () => {
          this.showCover = true;
          document.body.style.overflow = 'hidden'
          this.showLogo = false;
        });
      } else {
        this.showCover = true;
        document.body.style.overflow = 'hidden'
        this.showLogo = false;
      }

    // 无封面
    } else {
      this.showLogo = false;
      this.showCover = false;
      document.body.style.overflow = 'auto'
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(e) {
    this.lastScrollY = e.touches[0].pageY;
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(e) {
    e.preventDefault();
    e.stopPropagation();
    this.coverPositionTop = Math.min(e.touches[0].pageY - this.lastScrollY, 0);
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(e) {
    const delta = e.changedTouches[0].pageY - this.lastScrollY;
    if (delta < -50) {
      this.showCover = false;
      document.body.style.overflow = 'auto'
    } else {
      this.coverPositionTop = 0;
    }
  }

  onShowActivity() {
    this.showActivityImage = true;
  }

  onPlayVideo() {
    this.coverVideo.nativeElement.play();
    this.showVideoPlayButton = false;
  }

  onPauseVideo() {
    this.coverVideo.nativeElement.pause();
    this.showVideoPlayButton = true;
  }
}
