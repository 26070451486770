import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringHelperService {

  constructor() { }

  urlSafeBase64Encode(text) {
    return btoa(text).replace(/\//g, '_').replace(/\+/g, '-');
  }
}
