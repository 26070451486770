import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PhotoSizeService {

  devicePixelRatio: number;
  maxPhotoWidth: number;
  // device independent pixels
  // 页面内实际的CSS像素尺寸
  windowDipWidth: number;

  constructor() {
    this.devicePixelRatio = window.devicePixelRatio || 2;
    this.windowDipWidth = window.innerWidth;
    const windowWidth = Math.round(window.innerWidth * devicePixelRatio);
    if (windowWidth > 2560) {
      this.maxPhotoWidth = 3000;
    } else if (windowWidth > 1920) {
      this.maxPhotoWidth = 2560;
    } else if (windowWidth > 1440) {
      this.maxPhotoWidth = 1920;
    } else if (windowWidth > 1280) {
      this.maxPhotoWidth = 1440;
    } else if (windowWidth > 1080) {
      this.maxPhotoWidth = 1280;
    } else if (windowWidth > 750) {
      this.maxPhotoWidth = 1080;
    } else if (windowWidth > 640) {
      this.maxPhotoWidth = 750;
    } else if (windowWidth > 480) {
      this.maxPhotoWidth = 640;
    } else {
      this.maxPhotoWidth = 480;
    }
  }

  // 限定宽（长自适应）
  headImageResizeParam(): string {
    return '?imageView2/2/w/' + this.maxPhotoWidth + '/q/100';
  }

  getPhotoResizeParam(columnCount: number): string {
    let width;
    switch (columnCount) {
      // 单列 限定宽（长自适应）
      case 1:
        return '?imageView2/2/w/' + this.maxPhotoWidth + '/q/100/interlace/1';
      // 两列 限定长宽最小值，居中裁剪
      case 2:
        width = Math.ceil((this.maxPhotoWidth - 2) / 2);
        return '?imageView2/1/w/' + width + '/h/' + width + '/q/100/interlace/1';
      // 三列 限定长宽最小值，居中裁剪
      case 3:
        width = Math.ceil((this.maxPhotoWidth - 4) / 3);
        return '?imageView2/1/w/' + width + '/h/' + width + '/q/100/interlace/1';
      // 时光轴 四列 限定长宽最小值，居中裁剪
      case 6:
        width = Math.ceil((this.maxPhotoWidth - 40) / 4);
        return '?imageView2/1/w/' + width + '/h/' + width + '/q/100/interlace/1';
      // 瀑布流 限定宽（长自适应）
      case 9:
        width = Math.ceil((this.maxPhotoWidth - 2) / 2);
        return '?imageView2/2/w/' + width + '/q/100/interlace/1';
      default:
        width = Math.ceil((this.maxPhotoWidth - 2) / 2);
        return '?imageView2/1/w/' + width + '/h/' + width + '/q/100/interlace/1';
    }
  }

  getPhotoCssWidth(columnCount: number): number {
    switch (columnCount) {
      // 单列 限定宽（长自适应）
      case 1:
        return this.windowDipWidth;

      // 两列 限定长宽最小值，居中裁剪
      case 2:
        return Math.floor((this.windowDipWidth - 2) / 2);

      // 三列 限定长宽最小值，居中裁剪
      case 3:
        return Math.floor((this.windowDipWidth - 4) / 3);

      // 时光轴 四列 限定长宽最小值，居中裁剪
      case 6:
        return Math.floor((this.windowDipWidth - 44) / 4);

      // 瀑布流 限定宽（长自适应）
      case 9:
        return Math.floor((this.windowDipWidth - 2) / 2);

      default:
        return Math.floor((this.windowDipWidth - 2) / 2);
    }
  }
}
