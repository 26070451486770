export class PhotoSearchParam {
  displayName: string;
  albumUuid: string;
  categoryUuid: string;
  type = 0; // 0 图片，1 视频
  hot = 0;
  sortField = 'sort';
  desc = 0;
  start = 0;
  limit = 10000;
}
