import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, ParamMap} from '@angular/router';
import { Title } from '@angular/platform-browser';
import {AlbumStatusEnum} from '../../constants/album-status-enum';
import {Album} from '../../models/album';
import {ApiService} from '../../services/api.service';

declare var wx: any;

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.css']
})
export class AlbumComponent implements OnInit {
  static DEFAULT_SHARE_IMAGE = '//file-dev.gogobamboo.com/code_files/bamboo-logo-sml.png';

  // 相册加载过程
  AlbumStatusEnum = AlbumStatusEnum;
  albumStatus = AlbumStatusEnum.STATUS_LOADING;
  showErrorPopup = false;
  errorMsg = '';

  // 相册数据
  album: Album;
  albumUuid: string;

  private debug = false;

  static htmlToPlaintext(text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) {}

  ngOnInit() {
    const albumUuid = this.route.snapshot.paramMap.get('albumUUID');
    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      this.debug = !!paramMap.get('debug');
    });
    if (!albumUuid) {
      this.albumStatus = AlbumStatusEnum.STATUS_NOT_FOUND;
      this.showErrorPopup = true;
      this.errorMsg = '地址错误';
      return;
    }
    this.albumUuid = albumUuid;
    this.loadAlbum();
  }

  private loadAlbum() {
    this.apiService.getAlbum(this.albumUuid).subscribe(res => {
      this.album = res.data ? res.data.album : null;
      if (this.album) {
        this.album.activityImageUrl = this.album.activityImageUrl ? this.album.activityImageUrl.replace('http:', '') : null;
        this.album.coverImageUrl = this.album.coverImageUrl ? this.album.coverImageUrl.replace('http:', '') : null;
        this.album.headImage1Url = this.album.headImage1Url ? this.album.headImage1Url.replace('http:', '') : null;
        this.album.shareImageUrl = this.album.shareImageUrl ? this.album.shareImageUrl.replace('http:', '') : null;
        this.album.summaryImageUrl = this.album.summaryImageUrl ? this.album.summaryImageUrl.replace('http:', '') : null;
      }
      if (this.album && this.album.name) {
        this.titleService.setTitle(this.album.name);
      }

      switch (res.code) {
        case 0:
          this.albumStatus = AlbumStatusEnum.STATUS_LOADED;
          this.showErrorPopup = false;
          this.wechatConfig();
          break;
        case 4001:
          this.albumStatus = AlbumStatusEnum.STATUS_NOT_FOUND;
          this.showErrorPopup = true;
          this.errorMsg = res.msg;
          break;
        case 4002:
          this.albumStatus = AlbumStatusEnum.STATUS_NOT_PUBLISH;
          this.showErrorPopup = true;
          this.errorMsg = res.msg;
          break;
        case 4003:
          this.albumStatus = AlbumStatusEnum.STATUS_NEED_VALIDATION;
          this.showErrorPopup = true;
          this.errorMsg = res.msg;
          break;
        default:
          this.albumStatus = AlbumStatusEnum.STATUS_NOT_FOUND;
          this.showErrorPopup = true;
          this.errorMsg = res.msg;
          break;
      }
    });
  }

  onValidateSuccess() {
    this.loadAlbum();
  }

  onTimeLine() {
    this.router.navigateByUrl('timeline/' + this.album.uuid);
  }

  wechatConfig() {
    if (!(/micromessenger/i).test(navigator.userAgent)) {
      return;
    }
    const currentUrl = location.href.split('#')[0];
    this.apiService.getWxJsApiConfig(currentUrl).subscribe(config => {
      wx.config({
        debug: this.debug,
        appId: config.appId,
        timestamp: config.timestamp,
        nonceStr: config.nonceStr,
        signature: config.signature,
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage'
        ]
      });
      wx.ready(() => {
        let shareIcon = AlbumComponent.DEFAULT_SHARE_IMAGE;
        let shareSummary = AlbumComponent.htmlToPlaintext(this.album.summary);
        let shareTitle = this.album.name;
        if (this.album.shareImageUrl) {
          shareIcon = this.album.shareImageUrl;
        }
        if (this.album.shareSummary) {
          shareSummary = this.album.shareSummary;
        }
        if (this.album.shareTitle) {
          shareTitle = this.album.shareTitle;
        }
        if (shareIcon && shareIcon.startsWith('//')) {
          shareIcon = 'http:' + shareIcon;
        }

        const shareConfig = {
          title: shareTitle, // 分享标题
          desc: shareSummary, // 分享描述
          link: currentUrl, // 分享链接
          imgUrl: shareIcon, // 分享图标
          success: () => {
            console.log('更新设置成功:', this);
          }
        };
        wx.updateAppMessageShareData(shareConfig);
        wx.updateTimelineShareData(shareConfig);

        const shareConfig2 = {
          title: shareTitle, // 分享标题
          desc: shareSummary, // 分享描述
          link: currentUrl, // 分享链接
          imgUrl: shareIcon, // 分享图标
          success: () => {
            this.apiService.incShareTimes(this.album.uuid).subscribe(res => {
              console.log('inc share times');
            });
            console.log('share success');
          }
        };
        wx.onMenuShareAppMessage(shareConfig2);
        wx.onMenuShareTimeline(shareConfig2);
      });
    });
  }
}
