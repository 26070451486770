import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import { fromEvent, timer } from 'rxjs'; //引入
import { debounce } from 'rxjs/operators';
import {Album} from '../../models/album';
import {PhotoSearchParam} from '../../models/photo-search-param';
import {ApiService} from '../../services/api.service';
import {PhotoList} from '../../models/photo-list';
import {PhotoTimelineList} from '../../models/photo-timeline-list';
import {PhotoSizeService} from '../../services/photo-size.service';
import {Photo} from '../../models/photo';
import {Router} from '@angular/router';
import {PhotoMergeService} from '../../services/photo-merge.service';
import {StringHelperService} from '../../services/string-helper.service';
import {PhotoMergeParam} from '../../models/photo-merge-param';
import {AlbumCoverService} from '../../services/album-cover.service';

declare var PhotoSwipe: any;
declare var PhotoSwipeUI_Default: any;
declare var $: any;

@Component({
  selector: 'app-album-photo-list',
  templateUrl: './album-photo-list.component.html',
  styleUrls: ['./album-photo-list.component.css']
})
export class AlbumPhotoListComponent implements OnInit {
  static PLACEHOLDER_IMAGE = '//file-dev.gogobamboo.com/code_files/photo-placeholder-more-1.png';
  static PLACEHOLDER_IMAGE_RATIO = 210 / 373;

  @Input()
  public album: Album;
  @ViewChild('staticBarElement', {static: true})
  public staticBarElement: ElementRef;
  @ViewChild('staticBarPlaceHolder', {static: true})
  public staticBarPlaceHolder: ElementRef;
  @ViewChild('photoListElement', {static: true})
  public photoListElement: ElementRef;
  @ViewChild('photoSwipeElement', {static: true})
  public photoSwipeElement: ElementRef;

  public subscribeScoll: any;
  public subscribeScoll2: any;
  public photoTimelineList: PhotoTimelineList = new PhotoTimelineList();
  public photoTimelineLists: any;
  public photoList: PhotoList = new PhotoList();
  public photoListForSwipe: any[] = [];
  public isLoadingPhoto = false;
  public photoShow = true;

  // Tab Bar
  public currentTab = 'category';
  public showColumns = false;
  public showLanguage = false;
  public currentColumn = 9;
  public currentColumnText = '瀑布流';
  public currentLanguage = 0;
  public showToolBarTab = true;
  public isTabBarFixed = false;
  public tabBarPlaceHolderHeight = 0;
  public lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
  public searchParam = new PhotoSearchParam();
  public currentOrderText = '顺序';

  // 图片展示
  public photoResizeParam;
  public videoFrameParam;
  public placeHolderImageUrl;
  public waterfallColumnHeight1 = 0;
  public waterfallColumnHeight2 = 0;
  public waterfallColumnMaxHeight = 0;
  public currentViewPhoto = null;

  // 图片预览
  public currentPhotoSwipeGallery;
  public showOriginalUrlBtn = true;
  // 查看原图的参数,有角标时拼上角标参数
  private originalImageParam = '?imageslim';
  // 当前预览的是不是图片
  public currentPreviewPhoto = true;

  // 拼图
  public hideMergeBtn = false;
  public showMergeTemplate = false;
  public isMerging = false;
  public mergePhotoMap = new Map<number, Photo>();
  public mergePhotoCount = 0;
  public canStartMerge = false;
  public mergeTemplate = 'long';
  public mergeName = '';
  public maxMergePhotoCount = 10;
  public minMergePhotoCount = 2;

  public toast = false;
  public toastText = '';


  constructor(
    private apiService: ApiService,
    private photoSizeService: PhotoSizeService,
    private photoMergeService: PhotoMergeService,
    private stringHelper: StringHelperService,
    private router: Router,
    private albumCoverService: AlbumCoverService,
  ) { }

  ngOnInit() {
    this.searchParam.albumUuid = this.album.uuid;
    this.mergeName = this.album.name;
    if (!this.album.allCategoryAtFirst && this.album.categoryList && this.album.categoryList.length > 0) {
      this.searchParam.categoryUuid = this.album.categoryList[0].uuid;
    }
    this.setCurrentColumn(this.album.photoColumn);
    // this.setCurrentColumn(1);
    // 使用div+background-image缩放和裁剪，这里统一大小，预览的时候也不会变形
    // this.photoResizeParam = this.photoSizeService.getPhotoResizeParam(this.album.photoColumn);
    this.photoResizeParam = this.photoSizeService.getPhotoResizeParam(1);
    this.videoFrameParam = '?vframe/png/offset/0/w/' + this.photoSizeService.maxPhotoWidth;
    this.placeHolderImageUrl = AlbumPhotoListComponent.PLACEHOLDER_IMAGE + this.photoResizeParam;

    if (this.album.subscriptType === 1 && this.album.subscriptImageUrl) {
      const subscriptUrl64 = this.stringHelper.urlSafeBase64Encode(this.album.subscriptImageUrl);
      this.originalImageParam = '?watermark/1/image/'
        + subscriptUrl64 + '/dissolve/50/gravity/SouthEast/dx/0/dy/0/ws/0.1|imageslim';
    }
    if (this.currentColumn !== 6) {
      this.reloadPhoto();
    }
    this.subscribeScoll2 = fromEvent(window, 'scroll')
      .subscribe(() => {
        this.onWindowScroll();//调用
    })
    this.subscribeScoll = fromEvent(window, 'scroll')
      .pipe(debounce(() => timer(1000)))
      .subscribe(() => {
        this.onWindowScrollEnd();//调用
    })
    $("#functionModal").on("click", (e) => {
      e.stopPropagation();
      this.showColumns = true
    });
    $(document).on("click", () => {
      this.showColumns = false
    });
  }

  reloadPhoto() {
    this.isLoadingPhoto = true;
    this.searchParam.start = 0;
    this.waterfallColumnHeight1 = 0;
    this.waterfallColumnHeight2 = 0;
    this.photoListForSwipe = [];
    this.currentViewPhoto = null;
    if (+this.currentColumn === 6) {
      this.apiService.getTimelinePhoto(this.searchParam).subscribe(photoTimelineList => {
        let photoTimelineLists = []
        for (var i = 0; i < photoTimelineList.list.length; i++) {
          if (i === 0) {
            photoTimelineList.list[i].show = true
          } else {
            photoTimelineList.list[i].show = false
          }
          photoTimelineLists = photoTimelineLists.concat(photoTimelineList.list[i].photos)
          this.processPhotoList(photoTimelineList.list[i]);
        }
        this.photoTimelineLists = photoTimelineLists
        this.photoTimelineList = photoTimelineList;
        this.photoShow = true
        this.isLoadingPhoto = false;
      });
    } else {
      let param = JSON.parse(JSON.stringify(this.searchParam))
      if (this.currentTab === 'hot' || param.type === 1) {
        delete param.categoryUuid
      }
      this.apiService.getPhoto(param).subscribe(photoList => {
        this.processPhotoList(photoList);
        this.photoList = photoList;
        this.photoShow = true
        this.isLoadingPhoto = false;
      });
    }
  }

  onTimeLineClick (index) {
    this.photoTimelineList.list[index].show = !this.photoTimelineList.list[index].show
  }

  onWindowScroll() {
    if (!this.hideMergeBtn) {
      this.hideMergeBtn = true
    }
  }

  onWindowScrollEnd () {
    if (this.hideMergeBtn) {
      this.hideMergeBtn = false
    }
    if (this.currentColumn !== 6) {
      this.computeCurrentViewPhoto()
    }
  }

  loadMorePhoto() {
    if (this.isLoadingPhoto || !this.photoList.hasNext) {
      return;
    }
    this.isLoadingPhoto = true;
    this.searchParam.start = this.photoList.list.length;
    let param = JSON.parse(JSON.stringify(this.searchParam))
    if (this.currentTab === 'hot' || param.type === 1) {
      delete param.categoryUuid
    }
    this.apiService.getPhoto(param).subscribe(photoList => {
      this.processPhotoList(photoList);
      for (const photo of photoList.list) {
        photo.url = photo.url ? photo.url.replace('http:', '') : ''
        this.photoList.list.push(photo);
      }
      this.photoList.hasNext = photoList.hasNext;
      this.photoList.start = photoList.start;
      this.photoList.total = photoList.total;
      this.isLoadingPhoto = false;
      // 再次检查
      this.loadMorePhotoIfNecessary();
    });
  }

  processPhotoList(photoList: PhotoList) {
    const lists = this.currentColumn === 6 ? photoList.photos : photoList.list
    for (const photo of lists) {
      if (!photo.width) {
        photo.width = 375;
      }
      if (!photo.height) {
        photo.height = 667;
      }
      photo.url = photo.url ? photo.url.replace('http:', '') : ''
      if (this.currentColumn === 9) {
        this.processPhoto_WaterFall(photo);
      } else {
        this.processPhoto_Column(photo);
      }
      // PhotoSwipe
      if (photo.type === 0) {
        this.photoListForSwipe.push({
          src: photo.displayUrl,
          w: photo.width,
          h: photo.height,
          msrc: photo.displayUrl,
          uuid: photo.uuid
        });
      } else {
        this.photoListForSwipe.push({
          html: `<div class="preview-video-hd">
                    <span class="vertical-helper"></span>
                    <video x5-video-player-type="h5-page" src="${photo.url}" poster="${photo.displayUrl}" class="pswp-video"></video>
                    <span class="pswp-video-play-btn icon-play-button"></span>
                 </div>`,
          uuid: photo.uuid
        });
      }
    }
  }

  // 瀑布流模式下处理图片
  private processPhoto_WaterFall(photo: Photo) {
    const photoDisplayWidth = this.photoSizeService.getPhotoCssWidth(this.currentColumn);
    const loadedPhotoWidth = this.photoSizeService.maxPhotoWidth;
    // 显示尺寸
    photo.displayWidth = photoDisplayWidth;
    photo.displayHeight = photoDisplayWidth * photo.height / photo.width;
    // 实际下载下来的图片尺寸
    photo.loadedWidth = loadedPhotoWidth;
    photo.loadedHeight = photo.height * photo.loadedWidth / photo.width;
    // 放在第一列
    if (this.waterfallColumnHeight1 <= this.waterfallColumnHeight2) {
      photo.left = 0;
      photo.top = this.waterfallColumnHeight1 + 2;
      this.waterfallColumnHeight1 += photo.displayHeight + 2;
    // 放在第二列
    } else {
      photo.left = photoDisplayWidth + 2;
      photo.top = this.waterfallColumnHeight2 + 2;
      this.waterfallColumnHeight2 += photo.displayHeight + 2;
    }
    this.waterfallColumnMaxHeight = Math.max(this.waterfallColumnHeight1, this.waterfallColumnHeight2);

    // 加载图片
    photo.displayUrl = photo.url + (photo.type === 0 ? this.photoResizeParam : this.videoFrameParam);
    const img = new Image();
    img.onload = () => {
      photo.loaded = true;
    };
    img.onerror = () => {
      img.src = photo.displayUrl + '&t=' + (new Date().getTime());
    };
    img.src = photo.displayUrl;
  }

  private processPhoto_Column(photo: Photo) {
    // 时光轴显示4列
    const photoDisplayWidth = this.photoSizeService.getPhotoCssWidth(this.currentColumn);
    const loadedPhotoWidth = this.photoSizeService.maxPhotoWidth;
    // 加载图片
    photo.displayUrl = photo.url + (photo.type === 0 ? this.photoResizeParam : this.videoFrameParam);
    photo.displayWidth = photoDisplayWidth;
    photo.displayHeight = photoDisplayWidth * AlbumPhotoListComponent.PLACEHOLDER_IMAGE_RATIO;
    const img = new Image();
    img.onload = () => {
      photo.loaded = true;
      // 实际下载下来的图片大小
      photo.loadedWidth = loadedPhotoWidth;
      photo.loadedHeight = photo.height * photo.loadedWidth / photo.width;

      // 计算图片显示尺寸
      photo.displayWidth = photo.displayHeight = photoDisplayWidth;
      if (this.currentColumn === 1) {
        photo.displayHeight = photo.height * (photo.displayWidth / photo.width);
      }
      photo.left = 0;
      photo.top = 0;
    };
    img.onerror = () => {
      img.src = photo.displayUrl + '&t=' + (new Date().getTime());
    };
    img.src = photo.displayUrl;
  }

  private computeCurrentViewPhoto() {
    let currentIndex = null
    let lists = this.photoList.list
    for (var i = 0; i < lists.length; i++) {
      const photo = lists[i]
      const el = document.getElementById(`photo_${photo.uuid}`)
      const viewHeight = window.innerHeight || document.documentElement.clientHeight;
      if (el && el.getBoundingClientRect().top > viewHeight) {
        currentIndex = i
        break
      }
    }
    if (!currentIndex) {
      this.currentViewPhoto = lists.length
    } else {
      this.currentViewPhoto = currentIndex
    }
  }

  @HostListener('document:scroll', ['$event'])
  onDocumentScroll(e) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    // scrolling down
    if (scrollTop > this.lastScrollTop) {
      // if (!this.isLoadingPhoto && this.photoList.hasNext) {
      if (!this.isLoadingPhoto && (this.currentColumn !== 6 && this.photoList.hasNext || this.currentColumn === 6 && this.photoTimelineList.hasNext)) {
        this.loadMorePhotoIfNecessary();
      }

      if (scrollTop > this.staticBarElement.nativeElement.offsetTop) {
        this.tabBarPlaceHolderHeight = this.staticBarElement.nativeElement.scrollHeight;
        this.isTabBarFixed = true;
      }
      // scrolling up
    } else {
      if (scrollTop < this.staticBarPlaceHolder.nativeElement.offsetTop) {
        this.tabBarPlaceHolderHeight = 0;
        this.isTabBarFixed = false;
      }
    }
    this.lastScrollTop = scrollTop;
  }

  loadMorePhotoIfNecessary() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const distanceToBottom = document.documentElement.scrollHeight -
      scrollTop - document.documentElement.clientHeight;
    if (distanceToBottom < 300) {
      this.loadMorePhoto();
    }
  }

  onCategoryTab() {
    if (this.currentTab !== 'category') {
    //   this.showToolBarTab = !this.showToolBarTab;
    // } else {
      this.photoShow = false
      this.currentColumn = this.album.photoColumn
      this.showToolBarTab = true;
      this.currentTab = 'category';
      if (!this.album.allCategoryAtFirst && this.album.categoryList && this.album.categoryList.length > 0) {
        this.searchParam.categoryUuid = this.album.categoryList[0].uuid;
      }
      this.searchParam.type = 0;
      this.searchParam.hot = 0;
      this.reloadPhoto();
    }
  }

  onHotTab () {
    if (this.currentTab !== 'hot') {
    //   this.showToolBarTab = !this.showToolBarTab;
    // } else {
      this.photoShow = false
      this.currentColumn = this.album.photoColumn
      this.showToolBarTab = true;
      this.currentTab = 'hot';
      this.searchParam.type = 0;
      this.searchParam.hot = 1;
      // this.searchParam.categoryUuid = null;
      this.reloadPhoto();
    }
  }

  onLanguage () {
    this.showLanguage = !this.showLanguage
    if (this.showLanguage) {
      this.showColumns = false
    }
  }
  onColumnTab() {
    this.showColumns = !this.showColumns
    if (this.showColumns) {
      this.showLanguage = false
    }
    // if (this.currentTab === 'column') {
    //   this.showToolBarTab = !this.showToolBarTab;
    // } else {
    //   this.showToolBarTab = true;
    //   this.columns = !this.columns
    // }
  }

  onOrderTab(tab) {
    this.showColumns = false;
    if (this.searchParam.desc == tab) {
      return
    }
    this.searchParam.desc = 1 - this.searchParam.desc;
    this.currentOrderText = this.searchParam.desc === 1 ? '倒序' : '顺序';
    this.reloadPhoto();
  }

  onMergeTab(flag) {
    this.showMergeTemplate = !this.showMergeTemplate
    if (!flag && this.isMerging) {
      this.isMerging = false;
      this.mergePhotoMap = new Map<number, Photo>();
      this.mergePhotoCount = 0;
      this.canStartMerge = false;
    }
  }
  onMyTab() {
    location.href = this.album.floatBtnLink
  }

  onChooseCategory(categoryUuid: string) {
    this.searchParam.categoryUuid = categoryUuid;
    this.reloadPhoto();
  }

  onChooseLanguage(language) {
    this.currentLanguage = language
  }

  onChooseColumn(columnCount: number) {
    const oldColumn = this.currentColumn
    if (columnCount === this.currentColumn) {
      this.showColumns = false
      return;
    }
    this.currentViewPhoto = null
    this.setCurrentColumn(columnCount);
    // 重置瀑布流模式的数据
    this.waterfallColumnHeight1 = 0;
    this.waterfallColumnHeight2 = 0;
    this.waterfallColumnMaxHeight = 0;
    if (this.currentColumn !== 6 && (oldColumn !== 6)) {
      this.photoList.list.splice(this.searchParam.limit);
      this.photoList.hasNext = true;
      this.photoList.start = 0;
      this.processPhotoList(this.photoList);
    }
    this.loadMorePhotoIfNecessary();
    this.showColumns = false
  }

  setCurrentColumn(columnCount: number) {
    if (this.currentColumn === columnCount) {
      return
    }
    const oldColummn = this.currentColumn
    this.currentColumn = columnCount;
    switch (this.currentColumn) {
      case 1:
        this.currentColumnText = '整列';
        break;
      case 2:
        this.currentColumnText = '两列';
        break;
      case 3:
        this.currentColumnText = '九宫格';
        break;
      case 6:
        this.currentColumnText = '时光轴';
        break;
      case 9:
        this.currentColumnText = '瀑布流';
        break;
    }
    // 时光轴默认倒序
    if (this.currentColumn === 6) {
      this.searchParam.desc = 1
    }
    if (oldColummn === 6 || this.currentColumn === 6) {
      this.reloadPhoto()
    }
  }

  onPhotoClick(hourIndex: number, photoIndex: number, photo: Photo) {
    let photoTimelineListsTemp = []
    let index:number
    if (+this.currentColumn === 6) {
      for (var i = 0; i < hourIndex; i++) {
        photoTimelineListsTemp = photoTimelineListsTemp.concat(this.photoTimelineList.list[i].photos)
      }
      index = photoTimelineListsTemp.length + photoIndex
    } else {
      index = photoIndex
    }
    if (this.isMerging) {
      if (photo.type === 1) {
        return;
      }
      this.photoClickedWhenMerging(photo);
      return;
    }
    this.currentPreviewPhoto = photo.type === 0;

    const options = {
      index: index,
      galleryUID: 'gallery_1',
      getThumbBoundsFn: (index: number) => {
        let thumbnail:any
        if (+this.currentColumn === 6) {
          thumbnail = document.getElementById('photo_' + this.photoTimelineLists[index].uuid);
        } else {
          thumbnail = document.getElementById('photo_' + this.photoList.list[index].uuid);
        }
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
        const rect = thumbnail.getBoundingClientRect();
        return {x: rect.left, y: rect.top + pageYScroll, w: rect.width};
      },
      showHideOpacity: true,
      shareEl: false,
      // isClickableElement: function (el) {
      //     return el.tagName === 'BUTTON';
      // },
      isClickableElement: (el) => true,
      getDoubleTapZoom: (isMouseClick, item) => item.initialZoomLevel,
      bgOpacity: 0.9
    };
    this.currentPhotoSwipeGallery = new PhotoSwipe(
      this.photoSwipeElement.nativeElement, PhotoSwipeUI_Default, this.photoListForSwipe, options);
    this.currentPhotoSwipeGallery.init();

    this.currentPhotoSwipeGallery.listen('afterChange', () => {
      this.currentPreviewPhoto = !this.currentPhotoSwipeGallery.currItem.html;
      this.showOriginalUrlBtn = true;
      $('.pswp__item video').each((i, elem) => {
        elem.pause();
        $(elem).siblings('.pswp-video-play-btn').show();
      });
    });
    this.currentPhotoSwipeGallery.listen('close', () => {
      this.currentPhotoSwipeGallery = null;
      // 停止视频播放
      $('.pswp__item video').each((i, elem) => {
        elem.pause();
        $(elem).siblings('.pswp-video-play-btn').show();
      });
    });
    this.currentPhotoSwipeGallery.listen('preventDragEvent', (e, isDown, preventObj) => {
      preventObj.prevent = true;
    });
  }

  @HostListener('document:tap', ['$event'])
  onDocumentClick(e) {
    const $target = $(e.target);
    if ($target.hasClass('pswp__item') || $target.parents('.pswp__item').length > 0) {
      // 如果查看的是图片点击后隐藏
      if (this.currentPreviewPhoto && this.currentPhotoSwipeGallery) {
        this.currentPhotoSwipeGallery.close();
      } else {
        // 视频播放
        if ($target.hasClass('pswp-video-play-btn')) {
          const $video = $target.siblings('video');
          $video.get(0).play();
          $video.one('ended', () => { $target.show(); });
          $target.hide();
        } else if ($target.hasClass('pswp-video')) {
          $target.get(0).pause();
          $target.siblings('.pswp-video-play-btn').show();
        }
      }
    // "查看原图"按钮
    } else if ($target.hasClass('view-original-btn')) {
      const curImg = $('.pswp__item').eq(1).find('img').not('.pswp__img--placeholder');
      let src = curImg.attr('src');
      const index = src.indexOf('?');
      if (index > 0) {
        src = src.substr(0, index);
      }
      src += this.originalImageParam;
      curImg.attr('src', src);
      this.showOriginalUrlBtn = false;
    }
  }

  photoClickedWhenMerging(photo: Photo) {
    if (photo.numInMerge) {
      this.mergePhotoMap.delete(photo.numInMerge);
      photo.numInMerge = null;
      --this.mergePhotoCount;
    } else {
      if (this.mergePhotoCount >= this.maxMergePhotoCount) {
        this.toast = true
        this.toastText = '最多可选10张'
        setTimeout(() => {
          this.toast = false
          this.toastText = ''
        }, 1000)
        return;
      }
      let curIndex = 1;
      for (let i = 1; i <= this.maxMergePhotoCount; ++i) {
        if (!this.mergePhotoMap.get(i)) {
          curIndex = i;
          break;
        }
      }
      photo.numInMerge = curIndex;
      this.mergePhotoMap.set(curIndex, photo);
      ++this.mergePhotoCount;
    }
    this.canStartMerge = this.mergePhotoCount >= this.minMergePhotoCount
      && this.mergePhotoCount <= this.maxMergePhotoCount;
  }
  // 开始拼图
  onNextMerge() {
    this.showMergeTemplate = false;
    this.isMerging = !this.isMerging;
    if (!this.isMerging) {
      this.mergePhotoMap = new Map<number, Photo>();
      this.mergePhotoCount = 0;
      this.canStartMerge = false;
    }
  }
  // 下一步
  onStartMerge() {
    if (!this.canStartMerge) {
      this.toast = true
      this.toastText = `请至少选择${this.minMergePhotoCount}张图片`
      setTimeout(() => {
        this.toast = false
        this.toastText = ''
      }, 1000)
      return;
    }
    const photoMergeParam = new PhotoMergeParam();
    photoMergeParam.name = this.mergeName
    photoMergeParam.albumUuid = this.album.uuid;
    photoMergeParam.mergeTemplate = this.mergeTemplate;
    const photos: Photo[] = [];
    for (let i = 1; i <= this.maxMergePhotoCount; ++i) {
      const photo = this.mergePhotoMap.get(i);
      if (photo) {
        photos.push(photo);
      }
    }
    photoMergeParam.photos = photos;
    this.photoMergeService.saveMergeParam(photoMergeParam);
    this.router.navigate(['merge']);
  }
  // 生成gif
  onGenerateGif() {
    if (!this.canStartMerge) {
      return;
    }
    const photoMergeParam = new PhotoMergeParam();
    const albumUuid = this.album.uuid
    photoMergeParam.mergeTemplate = this.mergeTemplate;
    const photos: any[] = [];
    for (let i = 1; i <= this.maxMergePhotoCount; ++i) {
      const photo = this.mergePhotoMap.get(i);
      if (photo) {
        photos.push(photo.uuid);
      }
    }
    photoMergeParam.photos = photos;
    this.apiService.generateGif(albumUuid, photoMergeParam).subscribe(data => {
      photoMergeParam.gifUuid = data.gifUuid
      this.photoMergeService.saveMergeParam(photoMergeParam);
      this.router.navigate(['merge']);
    });
  }

  onMergeTemplate(templateName: string) {
    this.mergeTemplate = templateName;
    this.maxMergePhotoCount = this.photoMergeService.Templates[templateName].maxPhotoNum;
    this.minMergePhotoCount = this.photoMergeService.Templates[templateName].minPhotoNum;
    this.canStartMerge = this.mergePhotoCount >= this.minMergePhotoCount
      && this.mergePhotoCount <= this.maxMergePhotoCount;
  }

  onBackToTop() {
    document.documentElement.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }

  onRightCornerLogo() {
    // 为了保证返回到相册页面时不再显示封面
    this.albumCoverService.setShowCover(false, this.album.uuid);
  }

  onVideosTab() {
    this.photoShow = false
    this.currentColumn = 1;
    this.searchParam.type = 1;
    this.searchParam.hot = 0;
    this.currentTab = 'videos';
    this.reloadPhoto();
  }
}
