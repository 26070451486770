import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AlbumTimeline} from '../../models/album-timeline';
import {ApiService} from '../../services/api.service';
import {AlbumCoverService} from '../../services/album-cover.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {

  albumTimelines: AlbumTimeline[];

  private imageResizeParam = '?imageView2/1/w/' + 240 + '/h/' + 135 + '/interlace/1/q/100';
  private videoImageParam = '?vframe/png/offset/0/w/' + 240 + '/h/' + 135;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private albumCoverService: AlbumCoverService,
  ) { }

  ngOnInit() {
    const albumUuid = this.route.snapshot.paramMap.get('albumUUID');
    this.apiService.getAlbumTimeline(albumUuid).subscribe(res => {
      for (const albumTimeline of res) {
        if (albumTimeline.coverType === 1 && albumTimeline.coverImageUrl) {
          albumTimeline.imgUrl = albumTimeline.coverImageUrl + this.imageResizeParam;
        } else if (albumTimeline.coverType === 0 && albumTimeline.coverVideoUrl) {
          albumTimeline.imgUrl = albumTimeline.coverVideoUrl + this.videoImageParam;
        }
      }
      this.albumTimelines = res;
    });

    // 为了保证返回到相册页面时不再显示封面
    this.albumCoverService.setShowCover(false, albumUuid);
  }

  onAlbum(albumTimeline: AlbumTimeline) {
    this.router.navigateByUrl('album/' + albumTimeline.uuid);
  }
}
