/**
 * 相册加载状态
 */
export enum AlbumStatusEnum {
  STATUS_LOADING,
  STATUS_NOT_FOUND,
  STATUS_NOT_PUBLISH,
  STATUS_NEED_VALIDATION,
  STATUS_LOADED,
}
