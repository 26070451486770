import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlbumCoverService {

  constructor() { }

  setShowCover(isShowCover: boolean, albumUuid: string) {
    if (isShowCover) {
      localStorage.removeItem('hideCover:' + albumUuid);
    } else {
      localStorage.setItem('hideCover:' + albumUuid, '1');
    }
  }

  getShowCover(albumUuid: string): boolean {
    const isShowCover = !localStorage.getItem('hideCover:' + albumUuid);
    if (!isShowCover) {
      setTimeout(() => {
        this.setShowCover(true, albumUuid);
      }, 3000);
    }
    return isShowCover;
  }
}
