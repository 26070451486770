import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bottom-icons',
  templateUrl: './bottom-icons.component.html',
  styleUrls: ['./bottom-icons.component.css']
})
export class BottomIconsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
