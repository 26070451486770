import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MergeComponent} from './pages/merge/merge.component';
import {TimelineComponent} from './pages/timeline/timeline.component';
import {AlbumComponent} from './pages/album/album.component';

const routes: Routes = [
  {path: 'album/:albumUUID', component: AlbumComponent},
  {path: 'merge', component: MergeComponent},
  {path: 'merge/:mergeUUID', component: MergeComponent},
  {path: 'timeline/:albumUUID', component: TimelineComponent},

  {path: '**', component: AlbumComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
